import SalonService from "@/service/SalonService";
import {onBeforeMount, ref} from "vue";
import {useToast} from "primevue/usetoast";

export function useRegisterSalon() {
    const salonService = new SalonService();
    return {salonService}
}

export function useSalonList() {
    const salons = ref([]);
    const totalSalons = ref(0);
    const salonService = new SalonService();

    const getSalons = async (query) => {
        const res = await salonService.getAll(query)
        const data = res.data
        salons.value = data.salons
        totalSalons.value = data.total
    }

    return {salons, totalSalons, salonService, getSalons}
}

export function useSalonForm() {
    const salon = ref({});
    const center = ref({lat: 0, lng: 0})
    const markers = ref([]);
    const salonService = new SalonService();
    const toast = useToast();
    const loading = ref(false);

    const getDetailSalon = async (id) => {
        const res = await salonService.getOne(id)
        const data = res.data
        salon.value = data
        salon.value['isActive'] = salon.value.status === 3

        markers.value = [
            {
                position: {
                    lat: data.lat, lng: data.long
                }
            }
        ]
        center.value = {lat: data.lat, lng: data.long}
    }

    const create = async (data) => {
        const res = await salonService.createSalon(data)
        if (res.status !== 200) {
            console.error(res.data)
        }
    }

    const update = async (id, data) => {
        await salonService.resetStatusSalon(id)
        const res = await salonService.updateSalon(id, data)
        if (res.status !== 200) console.error(res.data)
    }

    const uploadMainBanner = async (id, image) => {
        const formData = new FormData()
        formData.append("image", image)
        await salonService.uploadMainBanner(id, formData)
    }

    const uploadMainBannerByAdminSalon = async (image) => {
        const formData = new FormData()
        formData.append("image", image)
        await salonService.uploadMainBannerByAdminSalon(formData)
    }

    const approve = async (id) => {
        loading.value = true
        var resp = await salonService.approveSalon(id)

        loading.value = false
        if (resp.status === 200) {
            salon.value.status = 1
            toast.add({
                severity: 'success',
                closable: true,
                summary: 'Berhasil',
                detail: 'Disetujui',
                life: 3000,
            })
        } else {
            toast.add({
                severity: 'error',
                closable: true,
                summary: 'Error',
                detail: resp.data.message,
                life: 3000,
            })
        }
    }

    const deny = async (id, reason) => {
        loading.value = true
        var resp = await salonService.denySalon(id, reason)

        loading.value = false
        if (resp.status === 200) {
            salon.value.status = 2
            toast.add({
                severity: 'info',
                closable: true,
                summary: 'Berhasil',
                detail: 'Denied',
                life: 3000,
            })
        } else {
            toast.add({
                severity: 'error',
                closable: true,
                summary: 'Error',
                detail: resp.data.message,
                life: 3000,
            })
        }

        return resp;
    }

    const activeInactiveSalon = async (id, isActive) => {
        let status = isActive ? 3 : 4
        let msg = isActive ? 'Berhasil mengaktifkan Salon' : 'Berhasil me-nonaktifkan salon'
        var resp =  await salonService.activeInactiveSalon(id, status)

        if (resp.status === 200) {
            toast.add({
                severity: 'success',
                closable: true,
                summary: 'Berhasil',
                detail: msg,
                life: 3000,
            })
        } else {
            toast.add({
                severity: 'error',
                closable: true,
                summary: 'Error',
                detail: resp.data.message,
                life: 3000,
            })
        }
    }

    const serviceTypes = {
        0: 'Kunjungan ke Salon',
        1: 'Pesan ke Rumah',
    };

    return {salon, markers, center, serviceTypes, loading, getDetailSalon, create, update, approve, deny, uploadMainBanner, uploadMainBannerByAdminSalon, activeInactiveSalon}
}

export function useSalonDropdown() {
    const salons = ref([]);
    const salonService = new SalonService();

    const getSalonDropdown = async () => {
        const {data} = await salonService.getDropdown({})
        const tempSalons = {}
        data?.salons.forEach((salon) => {
            tempSalons[salon.id] = salon.name
        })
        salons.value = tempSalons
    }

    onBeforeMount(async () => {
        await getSalonDropdown();
    })

    return {salons, getSalonDropdown}
}

export function useSalonDetail(id) {
    const banners = ref([])
    const salonService = new SalonService()

    const getBanners = async () => {
        const {data} = await salonService.getBanners(id)
        banners.value = data.salons
    }

    const deleteBanner = async (bannerId) => {
        await salonService.deleteBanner(id, bannerId)
    }

    const uploadBanners = async (images) => {
        const formData = new FormData()
        formData.append("images", images.value)
        await salonService.uploadBanners(id, formData)
    }

    return {banners, getBanners, deleteBanner, uploadBanners}
}

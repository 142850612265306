<template>
  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '450px' }"
    header="Akun Bank"
    :modal="true"
  >
    <Form
      :initial-value="initialData"
      :salon-name="props.salonName"
      @submit="submitBankAccount"
    />
  </Dialog>

  <div class="mb-5 card">
    <Table
      :rows="rows"
      :columns="columns"
      :data="bankAccounts"
      :total-items="totalBankAccounts"
      main-url="bank-accounts"
      @on-page-change="onPageChange"
    >
      <template #start>
        <Toolbar class="tw-mb-8 tw-p-3">
          <template #start>
            <div>
              <Button
                label="Tambah"
                icon="pi pi-plus"
                class="p-button-sm p-button-success tw-bg-blue-light"
                @click="createBankAccount"
              />
            </div>
          </template>
        </Toolbar>
      </template>

      <template #header>
        <h1>Akun Bank</h1>
      </template>
      
      <template #column>
        <Column header="Aksi">
          <template #body="context">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-warning"
              @click="editBankAccount(context.data)"
            />
            <Button
              icon="pi pi-trash"
              class="mt-2 p-button-rounded p-button-danger"
              @click="deleteRecord(context.data.id)"
            />
          </template>
        </Column>
      </template>
    </Table>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';

import Table from '@/components/dashboard/Table';
import {
  useBankAccountForm,
  useBankAccountList,
} from '@/composables/bank_accounts';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import Form from './Form';
import { reset } from '@formkit/vue';
import { useAuthRole } from '@/composables/auth';

const props = defineProps({
  salonName: String,
});
const rows = ref(10);
const showDialog = ref(false);
const isEdit = ref(false);

const confirm = useConfirm();
const toast = useToast();
const route = useRoute();
const role = useAuthRole();
const { bankAccounts, totalBankAccounts, getBankAccounts, deleteBank } =
  useBankAccountList();
const { create, update } = useBankAccountForm();
const initialData = ref({
  salon_id: route.params.id,
});

const columns = [
  { field: 'id', header: 'Id' },
  { field: 'bank_id', header: 'ID Bank' },
  { field: 'bank_name', header: 'Nama Bank' },
  { field: 'account_no', header: 'Nomor Akun Bank' },
  { field: 'salon_name', header: 'Nama Salon' },
  { field: 'name', header: 'Name' },
  { field: 'is_publish', header: 'Apakah dipublish' },
];

const createBankAccount = () => {
  initialData.value = { salon_id: route.params.id || role.salonId.value };
  showDialog.value = true;
  isEdit.value = false;
};

const onPageChange = ({ rows, first }) => {
  getBankAccounts({
    limit: rows,
    offset: first,
    salon_id: route.params.id || role.salonId.value,
  });
};

const deleteRecord = (id) => {
  confirm.require({
    message: 'Apakah Anda akan menghapus akun Bank ini?',
    header: 'Konfirmasi',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      await deleteBank(id);
      await getBankAccounts({
        limit: rows.value,
        salon_id: route.params.id || role.salonId.value,
      });
      toast.add({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'Record deleted',
        life: 3000,
      });
    },
  });
};

const editBankAccount = (data) => {
  isEdit.value = true;
  showDialog.value = true;
  initialData.value = { ...data };
};

const submitBankAccount = async (data) => {
  if (isEdit.value) await update(initialData.value?.id, data);
  else await create(data);
  await getBankAccounts({
    limit: rows.value,
    salon_id: route.params.id || role.salonId.value,
  });
  showDialog.value = false;
  reset('bank-accounts');
  isEdit.value = false;
};

onMounted(async () => {
  await getBankAccounts({
    limit: rows.value,
    salon_id: route.params.id || role.salonId.value,
  });
});
</script>

<style></style>

<template>
  <div>
    <Dialog
      header="Tambah Banner Baru"
      v-model:visible="displayCreateModal"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '60vw' }"
      :modal="true"
    >
      <FormKit
        v-model="data"
        :type="uploadImage"
        name="image"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikClass"
        :src="null"
      />
      <template #footer>
        <div class="p-fluid tw-mt-10 tw-mb-2">
          <Button label="Submit" @click="submitBanner" :disabled="!data" />
        </div>
      </template>
    </Dialog>
  </div>

  <div class="mb-5 card">
    <h1 class="tw-font-bold">Daftar Banner</h1>
    <Toolbar class="tw-mb-8 tw-mt-4">
      <template #start>
        <div>
          <Button
            label="Tambah"
            icon="pi pi-plus"
            class="p-button-sm p-button-success tw-bg-blue-light"
            @click="toggleCreateModal"
          />
        </div>
      </template>
    </Toolbar>

    <div class="tw-px-2">
      <Galleria :value="banners" v-model:active-index="activeIndex">
        <template #header>
          <div class="tw-flex tw-w-full tw-bg-black tw-p-2 tw-content-center tw-justify-between">
            <div class="tw-text-white tw-font-bold tw-text-xl tw-my-auto">
              <p>Banner {{ activeIndex + 1 }}</p>
            </div>
            <Button
              class="p-button-danger"
              label="Hapus"
              @click="showDeleteConfirmation"
            />
          </div>
        </template>
        
        <template #item="slotProps">
          <img
            :src="slotProps.item.image_url"
            :alt="slotProps.item.image_url"
          />
        </template>
      </Galleria>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onBeforeMount, ref } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { defaultFormikClass } from '@/constants/formik';

import { useSalonDetail } from '@/composables/salon';
import { createInput } from '@formkit/vue';
import FormUploadImage from '@/components/form/FormUploadImage';

const activeIndex = ref(0);
const displayCreateModal = ref(false);
const data = ref(null);
const uploadImage = createInput(FormUploadImage, {
  props: ['src', 'upload'],
});

const confirm = useConfirm();
const toast = useToast();

const props = defineProps({
  salonId: Number,
});

const { banners, getBanners, deleteBanner, uploadBanners } = useSalonDetail(
  props.salonId
);

const submitBanner = async () => {
  await uploadBanners(data);
  await getBanners();
  toast.add({
    severity: 'success',
    summary: 'Confirmed',
    detail: 'Banner uploaded',
    life: 3000,
  });
  displayCreateModal.value = false;
  data.value = null;
};

const showDeleteConfirmation = () => {
  confirm.require({
    message: 'Do you want to delete this banner?',
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      try {
        const bannerId = banners.value[activeIndex.value].id;
        await deleteBanner(bannerId);
        toast.add({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'Banner deleted',
          life: 3000,
        });
        await getBanners();
      } catch (e) {
        toast.add({
          severity: 'error',
          summary: 'Failed Delete Banner',
          detail: 'Failed to delete banner',
          life: 3000,
        });
      }
    },
  });
};

const toggleCreateModal = () => {
  displayCreateModal.value = !displayCreateModal.value;
};

onBeforeMount(async () => {
  await getBanners();
});
</script>

<template>
  <div>
    <slot name="start">
      <Toolbar v-if="createRouteName" class="tw-mb-8 tw-p-3">
        <template #start>
          <div>
            <Button
              label="Tambah"
              icon="pi pi-plus"
              class="p-button-sm p-button-success tw-bg-blue-light"
              @click="navigateToCreate"
            />
          </div>
        </template>
      </Toolbar>
    </slot>

    <DataTable
      :value="data"
      :paginator="true"
      :first="first"
      :rows="rows"
      :total-records="totalItems"
      :always-show-paginator="true"
      :lazy="true"
      @page="$emit('onPageChange', $event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="perPageOptions"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    >
      <template #header>
        <slot name="header" />
      </template>

      <div v-if="columns.length > 0">
        <Column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :header="column.header"
        >
          <template v-if="column.process" #body="slotProps">
            {{ column.process(slotProps.data[column.field]) }}
          </template>
        </Column>
        <slot name="column" />
      </div>

      <template #empty>
        <div
          class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
        >
          Tidak ada data yang ditemukan
        </div>
      </template>
      
      <template #loading>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
          Loading records, please wait...
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    first: {
      type: Number,
      default: 0,
    },
    data: Array,
    columns: {
      type: Array,
      default() {
        return [{ field: '', header: 'Actions' }];
      },
    },
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    mainUrl: {
      type: String,
      required: true,
    },
    createRouteName: {
      type: String,
      required: false,
    },
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: this.createRouteName });
    },
  },
};
</script>

<style scoped></style>
